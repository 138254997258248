import { Circle, Path, Rect, Svg } from '~/components/html'

export function AiOutlineForm({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 1024 1024' width={size}>
      <Path d='M904 512h-56c-4.4 0-8 3.6-8 8v320H184V184h320c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V520c0-4.4-3.6-8-8-8z' />
      <Path d='M355.9 534.9 354 653.8c-.1 8.9 7.1 16.2 16 16.2h.4l118-2.9c2-.1 4-.9 5.4-2.3l415.9-415c3.1-3.1 3.1-8.2 0-11.3L785.4 114.3c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-415.8 415a8.3 8.3 0 0 0-2.3 5.6zm63.5 23.6L779.7 199l45.2 45.1-360.5 359.7-45.7 1.1.7-46.4z' />
    </Svg>
  )
}

export function IoChevronBack({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 512 512' width={size}>
      <Path
        d='M328 112 184 256l144 144'
        fill='none'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='48px'
      />
    </Svg>
  )
}

export function BsTranslate({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 16 16' width={size}>
      <Path d='M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z' />
      <Path d='M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z' />
    </Svg>
  )
}

export function AiOutlineEdit({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 1024 1024' width={size}>
      <Path d='M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z' />
    </Svg>
  )
}

export function AiOutlineFileText({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 1024 1024' width={size}>
      <Path d='M854.6 288.6 639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z' />
    </Svg>
  )
}

export function BiRegularTrain({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 24 24' width={size}>
      <Circle cx='8.5' cy='14.5' r='1.5' />
      <Circle cx='15.5' cy='14.5' r='1.5' />
      <Path d='M18.87 3.34A3.55 3.55 0 0 0 16.38 2H7.62a3.47 3.47 0 0 0-2.5 1.35A4.32 4.32 0 0 0 4 6v12a1 1 0 0 0 1 1h2l-2 3h2.32L8 21h8l.68 1H19l-2-3h2a1 1 0 0 0 1-1V6a4.15 4.15 0 0 0-1.13-2.66zM7.62 4h8.72a1.77 1.77 0 0 1 1 .66 3.25 3.25 0 0 1 .25.34H6.39a2.3 2.3 0 0 1 .25-.35A1.65 1.65 0 0 1 7.62 4zM6 8V7h12v3H6zm12 9H6v-5h12z' />
    </Svg>
  )
}

export function FaSolidHandSparkles({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 640 512' width={size}>
      <Path d='M320 0c17.7 0 32 14.3 32 32v208c0 8.8 7.2 16 16 16s16-7.2 16-16V64c0-17.7 14.3-32 32-32s32 14.3 32 32v176c0 8.8 7.2 16 16 16s16-7.2 16-16V128c0-17.7 14.3-32 32-32s32 14.3 32 32v195.1c-11.9 4.8-21.3 14.9-25 27.8l-8.9 31.2-31.2 8.9c-18.3 5.3-30.9 22-30.9 41 0 18.9 12.5 35.6 30.6 40.9C448.4 497.4 409.9 512 368 512h-19.2c-59.6 0-116.9-22.9-160-64L76.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V64c0-17.7 14.3-32 32-32s32 14.3 32 32v176c0 8.8 7.2 16 16 16s16-7.2 16-16V32c0-17.7 14.3-32 32-32zm-7.3 326.6c-1.1-3.9-4.7-6.6-8.7-6.6s-7.6 2.7-8.7 6.6L288 352l-25.4 7.3c-3.9 1.1-6.6 4.7-6.6 8.7s2.7 7.6 6.6 8.7L288 384l7.3 25.4c1.1 3.9 4.7 6.6 8.7 6.6s7.6-2.7 8.7-6.6L320 384l25.4-7.3c3.9-1.1 6.6-4.7 6.6-8.7s-2.7-7.6-6.6-8.7L320 352l-7.3-25.4zM104 120l48.3 13.8c4.6 1.3 7.7 5.5 7.7 10.2s-3.1 8.9-7.7 10.2L104 168l-13.8 48.3c-1.3 4.6-5.5 7.7-10.2 7.7s-8.9-3.1-10.2-7.7L56 168 7.7 154.2C3.1 152.9 0 148.7 0 144s3.1-8.9 7.7-10.2L56 120l13.8-48.3C71.1 67.1 75.3 64 80 64s8.9 3.1 10.2 7.7L104 120zm480 288 48.3 13.8c4.6 1.3 7.7 5.5 7.7 10.2s-3.1 8.9-7.7 10.2L584 456l-13.8 48.3c-1.3 4.6-5.5 7.7-10.2 7.7s-8.9-3.1-10.2-7.7L536 456l-48.3-13.8c-4.6-1.3-7.7-5.5-7.7-10.2s3.1-8.9 7.7-10.2L536 408l13.8-48.3c1.3-4.6 5.5-7.7 10.2-7.7s8.9 3.1 10.2 7.7L584 408z' />
    </Svg>
  )
}

export function IoBusOutline({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 512 512' width={size}>
      <Rect
        fill='none'
        height='192'
        rx='32'
        ry='32'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='32px'
        width='352'
        x='80'
        y='112'
      />
      <Rect
        fill='none'
        height='128'
        rx='32'
        ry='32'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='32px'
        width='352'
        x='80'
        y='304'
      />
      <Path
        d='M400 112H112a32.09 32.09 0 0 1-32-32h0a32.09 32.09 0 0 1 32-32h288a32.09 32.09 0 0 1 32 32h0a32.09 32.09 0 0 1-32 32ZM144 432v22a10 10 0 0 1-10 10h-28a10 10 0 0 1-10-10v-22ZM416 432v22a10 10 0 0 1-10 10h-28a10 10 0 0 1-10-10v-22Z'
        fill='none'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='32px'
      />
      <Circle cx='368' cy='368' fill='none' r='16' stroke={color} stroke-linejoin='round' stroke-width='32px' />
      <Circle cx='144' cy='368' fill='none' r='16' stroke={color} stroke-linejoin='round' stroke-width='32px' />
      <Path
        d='M256 112v192M80 80v288M432 80v288'
        fill='none'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='32px'
      />
    </Svg>
  )
}

export function IoPeopleCircleOutline({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 512 512' width={size}>
      <Path d='M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208Zm0-384c-97 0-176 79-176 176s79 176 176 176 176-78.95 176-176S353.05 80 256 80Z' />
      <Path d='M323.67 292c-17.4 0-34.21-7.72-47.34-21.73a83.76 83.76 0 0 1-22-51.32c-1.47-20.7 4.88-39.75 17.88-53.62S303.38 144 323.67 144c20.14 0 38.37 7.62 51.33 21.46s19.47 33 18 53.51a84 84 0 0 1-22 51.3C357.86 284.28 341.06 292 323.67 292Zm55.81-74ZM163.82 295.36c-29.76 0-55.93-27.51-58.33-61.33-1.23-17.32 4.15-33.33 15.17-45.08s26.22-18 43.15-18 32.12 6.44 43.07 18.14 16.5 27.82 15.25 45c-2.44 33.77-28.6 61.27-58.31 61.27ZM420.37 355.28c-1.59-4.7-5.46-9.71-13.22-14.46-23.46-14.33-52.32-21.91-83.48-21.91-30.57 0-60.23 7.9-83.53 22.25-26.25 16.17-43.89 39.75-51 68.18-1.68 6.69-4.13 19.14-1.51 26.11a192.18 192.18 0 0 0 232.75-80.17ZM163.63 401.37c7.07-28.21 22.12-51.73 45.47-70.75a8 8 0 0 0-2.59-13.77c-12-3.83-25.7-5.88-42.69-5.88-23.82 0-49.11 6.45-68.14 18.17-5.4 3.33-10.7 4.61-14.78 5.75a192.84 192.84 0 0 0 77.78 86.64l1.79-.14a102.82 102.82 0 0 1 3.16-20.02Z' />
    </Svg>
  )
}

export function OcPeople2({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 16 16' width={size}>
      <Path d='M2 5.5a3.5 3.5 0 1 1 5.898 2.549 5.508 5.508 0 0 1 3.034 4.084.75.75 0 1 1-1.482.235 4 4 0 0 0-7.9 0 .75.75 0 0 1-1.482-.236A5.507 5.507 0 0 1 3.102 8.05 3.493 3.493 0 0 1 2 5.5ZM11 4a3.001 3.001 0 0 1 2.22 5.018 5.01 5.01 0 0 1 2.56 3.012.749.749 0 0 1-.885.954.752.752 0 0 1-.549-.514 3.507 3.507 0 0 0-2.522-2.372.75.75 0 0 1-.574-.73v-.352a.75.75 0 0 1 .416-.672A1.5 1.5 0 0 0 11 5.5.75.75 0 0 1 11 4Zm-5.5-.5a2 2 0 1 0-.001 3.999A2 2 0 0 0 5.5 3.5Z' />
    </Svg>
  )
}

export function AiOutlineFacebook({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 1024 1024' width={size}>
      <Path d='M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-32 736H663.9V602.2h104l15.6-120.7H663.9v-77.1c0-35 9.7-58.8 59.8-58.8h63.9v-108c-11.1-1.5-49-4.8-93.2-4.8-92.2 0-155.3 56.3-155.3 159.6v89H434.9v120.7h104.3V848H176V176h672v672z' />
    </Svg>
  )
}

export function AiOutlineInstagram({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 1024 1024' width={size}>
      <Path d='M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z' />
    </Svg>
  )
}

export function SiExpertsexchange({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 24 24' width={size}>
      <Path d='M7.28.9H0L8.36 12 0 23.1h7.28L15.64 12zM24 .9h-7.28l-2.3 3.06 3.64 4.82zm-9.58 19.15 2.3 3.05H24l-5.94-7.88z' />
    </Svg>
  )
}

export function AiOutlineHeart({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 1024 1024' width={size}>
      <Path d='M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z' />
    </Svg>
  )
}

export function RiMapBusLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M17 20H7v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1H3v-8H2V8h1V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v3h1v4h-1v8h-1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1Zm2-8V5H5v7h14Zm0 2H5v4h14v-4ZM6 15h4v2H6v-2Zm8 0h4v2h-4v-2Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMapCarLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M19 20H5v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V11l2.48-5.788A2 2 0 0 1 6.32 4H17.68a2 2 0 0 1 1.838 1.212L22 11v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1Zm1-7H4v5h16v-5ZM4.176 11h15.648l-2.143-5H6.32l-2.143 5ZM6.5 17a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm11 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMapRidingLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M5.5 21a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm13 2a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-7.477-8.695L13 12v6h-2v-5l-2.719-2.266A2 2 0 0 1 8 7.671l2.828-2.828a2 2 0 0 1 2.829 0l1.414 1.414a6.969 6.969 0 0 0 3.917 1.975l-.01 2.015a8.962 8.962 0 0 1-5.321-2.575l-2.634 2.633ZM16 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMapRunLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M9.83 8.79 8 9.456V13H6V8.05h.015l5.268-1.918c.244-.093.51-.14.782-.131a2.616 2.616 0 0 1 2.427 1.82c.186.583.356.977.51 1.181A4.992 4.992 0 0 0 19 11v2a6.986 6.986 0 0 1-5.402-2.547l-.697 3.956L15 16.17V23h-2v-5.898l-2.27-1.904-.727 4.127-6.894-1.215.348-1.97 4.924.868L9.83 8.79ZM13.5 5.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z'
        fill={color}
      >
      </Path>
    </Svg>
  )
}

export function RiMapTrainLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='m17.2 20 1.8 1.5v.5H5v-.5L6.8 20H5a2 2 0 0 1-2-2V7a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v11a2 2 0 0 1-2 2h-1.8ZM7 5a2 2 0 0 0-2 2v11h14V7a2 2 0 0 0-2-2H7Zm5 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4ZM6 7h12v4H6V7Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMapWalkLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='m7.617 8.712 3.205-2.328A1.995 1.995 0 0 1 12.065 6a2.616 2.616 0 0 1 2.427 1.82c.186.583.356.977.51 1.181A4.992 4.992 0 0 0 19 11v2a6.986 6.986 0 0 1-5.401-2.547l-.698 3.956 2.061 1.729 2.223 6.108-1.88.684-2.039-5.604-3.39-2.845a2 2 0 0 1-.714-1.904l.509-2.885-.677.492-2.127 2.928-1.618-1.176L7.6 8.7l.017.012ZM13.5 5.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4ZM10.53 18.681l-3.214 3.83-1.532-1.285 2.975-3.546.746-2.18 1.791 1.5-.766 1.681Z'
        fill={color}
      />
    </Svg>
  )
}

export function BsCheck({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 16 16' width={size}>
      <Path
        d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'
        fill={color}
      />
    </Svg>
  )
}

export function RiSystemCloseLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='m12 10.586 4.95-4.95 1.415 1.415-4.95 4.95 4.95 4.95-1.415 1.414-4.95-4.95-4.95 4.95-1.413-1.415 4.95-4.95-4.95-4.95L7.05 5.638l4.95 4.95Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiUserFacesGroup2Line({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M9.55 11.5a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm.45 8.248V16.4c0-.488.144-.937.404-1.338a6.474 6.474 0 0 0-5.033 1.417A8.012 8.012 0 0 0 10 19.749ZM4.453 14.66A8.462 8.462 0 0 1 9.5 13a8.48 8.48 0 0 1 2.967.532C13.345 13.19 14.392 13 15.5 13c1.66 0 3.185.424 4.206 1.156a8 8 0 1 0-15.253.504Zm14.426 1.426C18.486 15.553 17.171 15 15.5 15c-2.006 0-3.5.797-3.5 1.4V20a7.996 7.996 0 0 0 6.88-3.914ZM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm3.5-9.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiUserFacesParentLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M7 9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 2a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm10.5 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm2.5 6v-.5a2.5 2.5 0 0 0-5 0v.5h-2v-.5a4.5 4.5 0 1 1 9 0v.5h-2Zm-10 0v-4a3 3 0 1 0-6 0v4H2v-4a5 5 0 0 1 10 0v4h-2Z'
        fill={color}
      >
      </Path>
    </Svg>
  )
}

export function RiUserFacesTeamLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M12 11a5 5 0 0 1 5 5v6h-2v-6a3 3 0 0 0-2.824-2.995L12 13a3 3 0 0 0-2.995 2.824L9 16v6H7v-6a5 5 0 0 1 5-5Zm-6.5 3c.279 0 .55.033.81.094a5.948 5.948 0 0 0-.301 1.575L6 16v.086a1.493 1.493 0 0 0-.356-.08L5.5 16a1.5 1.5 0 0 0-1.493 1.355L4 17.5V22H2v-4.5A3.5 3.5 0 0 1 5.5 14Zm13 0a3.5 3.5 0 0 1 3.5 3.5V22h-2v-4.5a1.5 1.5 0 0 0-1.355-1.493L18.5 16c-.175 0-.343.03-.5.085V16c0-.666-.108-1.306-.308-1.904.258-.063.53-.096.808-.096Zm-13-6a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm-13 2a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm13 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiUserFacesUser2Line({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M4 22a8 8 0 1 1 16 0H4Zm9-5.917V20h4.659A6.008 6.008 0 0 0 13 16.083ZM11 20v-3.917A6.008 6.008 0 0 0 6.341 20H11Zm1-7c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6Zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Z'
        fill={color}
      />
    </Svg>
  )
}

export function IoSchool({ color, size }: IconProps) {
  return (
    <Svg fill={color} height={size} viewBox='0 0 512 512' width={size}>
      <Path d='M256 368a16 16 0 0 1-7.94-2.11L108 285.84a8 8 0 0 0-12 6.94V368a16 16 0 0 0 8.23 14l144 80a16 16 0 0 0 15.54 0l144-80a16 16 0 0 0 8.23-14v-75.22a8 8 0 0 0-12-6.94l-140.06 80.05A16 16 0 0 1 256 368Z' />
      <Path d='M495.92 190.5v-.11a16 16 0 0 0-8-12.28l-224-128a16 16 0 0 0-15.88 0l-224 128a16 16 0 0 0 0 27.78l224 128a16 16 0 0 0 15.88 0L461 221.28a2 2 0 0 1 3 1.74v144.53c0 8.61 6.62 16 15.23 16.43A16 16 0 0 0 496 368V192a14.76 14.76 0 0 0-.08-1.5Z' />
    </Svg>
  )
}

export function RiMapMapPinUserFill({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M17.084 15.812a7 7 0 1 0-10.168 0A5.996 5.996 0 0 1 12 13a5.996 5.996 0 0 1 5.084 2.812ZM12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728ZM12 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMapMap2Fill({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='m2 5 7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5Zm13 14.764V7.176l-.065.028L9 4.236v12.588l.065-.028L15 19.764Z'
        fill={color}
      />
    </Svg>
  )
}

export function AiTwotoneHeart({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 1024 1024' width={size}>
      <Path
        d='M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z'
        fill='#fff'
        fill-opacity='1'
      />
      <Path
        d='M679.7 201c-73.1 0-136.5 40.8-167.7 100.4C480.8 241.8 417.4 201 344.3 201c-104 0-188.3 82.6-188.3 184.5 0 201.2 356 429.3 356 429.3s356-228.1 356-429.3C868 283.6 783.7 201 679.7 201z'
        fill={color}
      />
    </Svg>
  )
}

export function RiSystemSearchLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='m18.031 16.617 4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617Zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.867-3.133-7-7-7s-7 3.133-7 7 3.133 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMediaEqualizerLine({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17Zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17Zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17ZM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z'
        fill={color}
      />
    </Svg>
  )
}

export function RiMapNavigationFill({ color, size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M2.9 2.3 21.704 8.57a.5.5 0 0 1 .028.938L13 13l-4.425 8.85a.5.5 0 0 1-.928-.086L2.261 2.912a.5.5 0 0 1 .638-.612Z'
        fill={color}
      />
    </Svg>
  )
}

export function AiFillHeart({ color, size }: IconProps) {
  return (
    <Svg
      fill={color}
      height={size}
      viewBox='0 0 1024 1024'
      width={size}
    >
      <Path d='M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z' />
    </Svg>
  )
}

export function FaSolidArrowLeft({ color, size }: IconProps) {
  return (
    <Svg
      fill={color}
      height={size}
      viewBox='0 0 448 512'
      width={size}
    >
      <Path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H109.3l105.3-105.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z' />
    </Svg>
  )
}

export function RiMapMapPinLine({ color, size }: IconProps) {
  return (
    <Svg
      height={size}
      viewBox='0 0 24 24'
      width={size}
    >
      <Path
        d='m12 20.9 4.95-4.95a7 7 0 1 0-9.9 0L12 20.9Zm0 2.828-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728ZM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z'
        fill={color}
      />
    </Svg>
  )
}

export function LocationWithPinPointNavigationIsolatedOnWhiteBackground({ size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path
        d='M21.054 24H2.946a2.753 2.753 0 0 1-2.739-3.006l1.446-15.45A2.738 2.738 0 0 1 4.392 3.05h15.217a2.737 2.737 0 0 1 2.738 2.495l1.446 15.449A2.755 2.755 0 0 1 21.054 24z'
        fill='#dcdcdc'
      />
      <Path
        d='m23.02 12.738-7.722.012-14.32.009-.187 2 12.596-.008L6.962 24h2.435l6.426-9.251 7.384-.011z'
        fill='#fff'
      />
      <Path
        d='M12 13a.744.744 0 0 1-.487-.18C11.328 12.662 7 8.915 7 5.063 7 2.271 9.243 0 12 0s5 2.271 5 5.063c0 3.853-4.328 7.6-4.513 7.757A.744.744 0 0 1 12 13z'
        fill='#222'
      />
      <Path d='M12 3c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z' fill='#fff' />
    </Svg>
  )
}

// e8d574

export function GraduationCapWithACrosshairIsolatedOnAWhiteBackground({ size }: IconProps) {
  return (
    <Svg height={size} viewBox='0 0 24 24' width={size}>
      <Path d='M12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10z' fill='#fff' />
      <Path
        d='M12 17.003c-1.363 0-2.727-.438-4.168-1.312A1.754 1.754 0 0 1 7 14.196V11.75a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 .75.75v2.446c0 .61-.319 1.184-.832 1.495-1.441.874-2.805 1.312-4.168 1.312z'
        fill='#222'
      />
      <Path
        d='M12 14a.754.754 0 0 1-.302-.063l-6.25-2.75a.752.752 0 0 1 0-1.374l6.25-2.75a.755.755 0 0 1 .604 0l6.25 2.75a.752.752 0 0 1 0 1.374l-6.25 2.75A.754.754 0 0 1 12 14z'
        fill='#e8d574'
      />
      <Path
        d='M12 4a1 1 0 0 1-1-1V1a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM12 24a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM23 13h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2zM3 13H1a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z'
        fill='rgba(0, 0, 0, 0)'
      />
    </Svg>
  )
}
